import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"faqs"}},[_c('ws-app-bar',{attrs:{"light":""}}),_c('section',{staticClass:"section-lg"},[_c(VContainer,[_c('h2',{staticClass:"text-center"},[_c('ws-gradient-text',{staticClass:"text-uppercase"},[_vm._v(" FREQUENTLY ASKED QUESTIONS ")])],1),_c('br'),_c(VRow,[_c(VExpansionPanels,{attrs:{"popout":"","xmultiple":""}},_vm._l((_vm.faqs),function(faq,i){return _c(VExpansionPanel,{key:i},[_c(VExpansionPanelHeader,{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(faq.Q)+" ")]),_c(VExpansionPanelContent,[_c('p',{staticClass:"body-1 xfont-weight-bold",domProps:{"textContent":_vm._s(faq.A)}})])],1)}),1),_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h2',[_vm._v("Didn't get the answer?")]),_c(VBtn,{attrs:{"color":"accent"},nativeOn:{"click":function($event){_vm.globals.interestDialog = false;
              _vm.showHideContactsCustom();}}},[_vm._v(" Let's Chat ")])],1)],1)],1)],1),_c('ws-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }